$brand-blue: #0099cc;
$brand-orange: #ff9933;
$light-grey: #e5e5e5;
$grey-blue: #c1c9d1;
$brand-dark: #324353;
$faint-blue: #f5f8fb;
$faint-green: #f5fbf7;

$header-ui-bg: #f3f8fc;
$low-translucency-white: rgba(255, 255, 255, 0.7);

$success-color: #28a745;
$warning-color: #ffbf00;
$warning-background: #fff9e8;
$invalid-color: #e46767;
$invalid-background: #fbebe7;

$btn-outline: $brand-dark;
$round-btn-outline: $brand-dark;

$max-width: 1280px;

@mixin btn(
  $color,
  $hover-bg: $color,
  $text: $color,
  $bg: $color,
  $hover-outline: $hover-bg
) {
  border: 1px solid $color;
  color: $text;
  background-color: $bg;
  padding: 7px 23px;
  border-radius: 7px;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  min-width: 135px;
  cursor: pointer;

  &:hover {
    border: 1px solid $hover-bg;
    background-color: $hover-bg;
    box-shadow: 2px 2px 6px 0 #a7afb7;
    text-decoration: none !important;
    color: #fff;
  }
}

@mixin round-btn {
  border: 1px solid $round-btn-outline;
  background-color: #fff;
  border-radius: (50%);
  display: block;
  height: 50px;
  width: 50px;
  cursor: pointer;

  svg {
    path,
    line {
      stroke: $round-btn-outline;
    }
  }

  &:hover {
    background-color: $round-btn-outline;
    box-shadow: 2px 2px 6px 0 #a7afb7;
    svg path {
      fill: $round-btn-outline;
    }
    path,
    line {
      stroke: #fff;
    }
  }
}

@import "./variables.scss";

@media print {
  #header-ui,
  #top-header-ui {
    display: none;
  }
}

:global(.frameless-tabs .react-tabs .d-lg-none.d-xl-none) {
  display: none;
}

:global(.frameless-tabs .react-tabs .d-none.d-lg-block.d-xl-block) {
  display: none !important;
}

#top-header-ui,
#header-ui {
  background-color: $header-ui-bg;
}

#top-header-ui {
  z-index: 3;
  position: relative;
  padding: 1rem 2rem;
}

#header-ui {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  padding: 0 2rem;
  margin-bottom: 25px;
}

.header-container {
  max-width: $max-width;
  margin: auto;
}

.tab {
  color: #8f8f8f;
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border-color: transparent;
  z-index: 1;
  position: relative;
  display: inline-block;
  padding: 1.5em 1.5em 1em;
  -webkit-text-decoration: none;
  text-decoration: none;
  margin: 0 7px;
  cursor: pointer;
  text-transform: uppercase;
  border-bottom: 5px solid $grey-blue;

  &:hover {
    color: $brand-dark;
    border-bottom: 5px solid $brand-dark;
  }

  &.active {
    pointer-events: none;
    color: $brand-dark;
    font-weight: bold;
    border-bottom: 5px solid $brand-dark;
  }
}

#frameless-container {
  .view-another {
    display: none;
  }
}
.pd-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.view-another {
  @include btn($btn-outline);
  background-color: #fff;
  margin: auto;
  margin-right: 0;
  float: right;
  margin-top: 15px;
  text-decoration: none !important;
}

.print-btn,
.send-btn {
  @include round-btn;
  padding: 0.75rem;
  margin-left: 1rem;
  i {
    color: #343a40;
  }
  svg {
    padding: 4px;
    height: 100%;
    width: 100%;
  }
}

.print-btn:hover,
.send-btn:hover {
  i {
    color: #fff;
  }
}

@media only screen and (max-width: 550px) {
  #top-header-ui {
    padding: 1rem 0.5rem;
  }
  .pd-0 {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

body {
  background: white;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.5;
  margin: 0px;
}

.root {
  font: "Source Sans Pro", sans-serif;
  --font-monospace: "Source Sans Pro", sans-serif;
  --font-monospace-size: 0.8rem !important;
  -webkit-font-smoothing: antialiased;
}

.cert-body {
  font-size: 16px;

  @media (max-width: 200px) {
    font-size: 10px;
  }
  @media (min-width: 599px) {
    font-size: 14px;
  }
  @media (min-width: 600px) {
    font-size: 18px;
  }
  @media (min-width: 900px) {
    font-size: 22px;
  }
  @media (min-width: 1200px) {
    font-size: 26px;
  }
  @media (min-width: 1800px) {
    font-size: 30px;
  }
}

.cert-title {
  font-size: 24px;

  @media (max-width: 200px) {
    font-size: 18px;
  }
  @media (min-width: 599px) {
    font-size: 22px;
  }
  @media (min-width: 600px) {
    font-size: 26px;
  }
  @media (min-width: 900px) {
    font-size: 30px;
  }
  @media (min-width: 1200px) {
    font-size: 34px;
  }
  @media (min-width: 1800px) {
    font-size: 38px;
  }
}

.transcript {
  font-size: 8px;

  @media (max-width: 200px) {
    font-size: 6px;
  }
  @media (min-width: 599px) {
    font-size: 8px;
  }
  @media (min-width: 600px) {
    font-size: 16px;
  }
  @media (min-width: 900px) {
    font-size: 20px;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (min-width: 1800px) {
    font-size: 28px;
  }
}
